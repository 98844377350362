<template>
  <div>
    Coordinator Requests
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.requests", 2)} - ${t("app.coordinator")} - ${t("app.mentorship")}`;

    return {
      documentTitle
    };
  }
};
</script>
